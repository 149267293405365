import { Divider } from "antd";

import { KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import {
  ICardPractitionerRes
} from "@types";
import { PractitionerItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";

export const ListPractitioners = () => {
  const { pastPractitionerList = [], presentPractitionerList = [], isLoadingList } = useListInfoType();

  useFetchInfoTypesService("Practitioners");

  const renderCardItems = (filteredCards: ICardPractitionerRes[]) => {
    return filteredCards.map((card: ICardPractitionerRes) => (
      <PractitionerItem
        key={card._id}
        card={card}
      />
    ));
  };

  return isLoadingList ? (
    <Loading />
  ) : (
    <WrapperCardList space={16}>
      {presentPractitionerList.length > 0 && (
        <CustomSpaceList>
          <KSemiText700>Practitioners</KSemiText700>
          {renderCardItems(presentPractitionerList)}
        </CustomSpaceList>
      )}
      {presentPractitionerList.length > 0 && pastPractitionerList.length > 0 && <Divider />}
      {pastPractitionerList.length > 0 && (
        <CustomSpaceList>
          <KSemiText700>No Longer Seeing</KSemiText700>
          {renderCardItems(pastPractitionerList)}
        </CustomSpaceList>
      )}
    </WrapperCardList>
  );
};
