import { useContext } from "react";

import { KSemiText700, Loading } from "@components";
import { KContext } from "@context";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { ICardHospitalizationsRes } from "@types";
import { HospitalizationItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";

const renderCardItems = (filteredCards: ICardHospitalizationsRes[]) => {
  return filteredCards.map((card: ICardHospitalizationsRes) => (
    <HospitalizationItem key={card._id} card={card} />
  ));
};

export const ListHospitalizations = () => {
  const { hospitalizationsList = [], isLoadingList } = useListInfoType();

  useFetchInfoTypesService("Hospitalizations");

  return isLoadingList ? (
    <Loading />
  ) : (
    <WrapperCardList space={16}>
      <CustomSpaceList>
        {hospitalizationsList.length > 0 && (
          <>
            <KSemiText700>Hospitalizations</KSemiText700>
            {renderCardItems(hospitalizationsList)}
          </>
        )}
      </CustomSpaceList>
    </WrapperCardList>
  );
};
