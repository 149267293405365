import { Divider } from "antd";
import { useContext, useEffect, useState } from "react";

import { KSemiText700, Loading } from "@components";
import { KContext } from "@context";
import { getAllergiesCard, getListCardsService } from "@services";
import {
  ECardTypeName,
  ETimePeriod,
  ICardAllergyRes,
  ICardQuery,
} from "@types";
import { AllergyItem } from "../item";
import { WrapperCardList, CustomSpaceList } from "../share-component";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";

const renderCardItems = (list: ICardAllergyRes[]) => {
  return list.map((card: ICardAllergyRes) => (
    <AllergyItem key={card._id} card={card} />
  ));
};

export const ListAllergies = () => {
  const { pastAllergyList, presentAllergyList, isLoadingList } =
    useListInfoType();

  useFetchInfoTypesService("Allergies");

  return isLoadingList ? (
    <Loading />
  ) : (
    <WrapperCardList space={16}>
      {!!presentAllergyList?.length && (
        <CustomSpaceList>
          <KSemiText700>Current Allergies</KSemiText700>
          {renderCardItems(presentAllergyList)}
        </CustomSpaceList>
      )}
      {!!(presentAllergyList?.length && pastAllergyList?.length) && <Divider />}
      {!!pastAllergyList?.length && (
        <CustomSpaceList>
          <KSemiText700>No Longer Experiencing</KSemiText700>
          {renderCardItems(pastAllergyList)}
        </CustomSpaceList>
      )}
    </WrapperCardList>
  );
};
