import { Flex, FlexProps, Spin } from 'antd';
import { FC } from 'react';

export const Loading: FC<Omit<FlexProps, "children">> = (props) => {
  return (
    <Flex align='center' justify='center' vertical flex={1} style={{ height: '100%' }} {...props}>
      <Spin size='large' />
    </Flex>
  );
};
