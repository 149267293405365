import { Divider } from "antd";

import { KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { ICardIdCardRes } from "@types";
import { IdCardItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";

export const ListIdCards = () => {
  const {
    pastCardIdList = [],
    presentCardIdList = [],
    isLoadingList,
  } = useListInfoType();

  useFetchInfoTypesService("IdCard");

  const renderCardItems = (card: ICardIdCardRes[]) => {
    return card.map((card: ICardIdCardRes) => (
      <IdCardItem key={card._id} card={card} />
    ));
  };

  return isLoadingList ? (
    <Loading />
  ) : (
    <WrapperCardList space={16}>
      {presentCardIdList.length > 0 && (
        <>
          <CustomSpaceList>
            <KSemiText700>ID Cards</KSemiText700>
            <CustomSpaceList space={24}>
              {renderCardItems(presentCardIdList)}
            </CustomSpaceList>
          </CustomSpaceList>
        </>
      )}
      {presentCardIdList.length > 0 && pastCardIdList.length > 0 && <Divider />}
      {pastCardIdList.length > 0 && (
        <CustomSpaceList>
          <KSemiText700>Expired ID Cards</KSemiText700>
          <CustomSpaceList space={24}>
            {renderCardItems(pastCardIdList)}
          </CustomSpaceList>
        </CustomSpaceList>
      )}
    </WrapperCardList>
  );
};
