import { useKContext } from "@context";
import {
  getListCardsEmergencyAccessService,
  getListCardsService,
  getListTemporaryService,
} from "@services";
import { useListInfoType } from "@store";
import { ECardType, ICardQuery, IParams } from "@types";
import { getQueryListInfoType } from "@utils/card";
import { getInfoCard } from "@utils/cardTypeIcon";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

type ISetListCardTypeMap = {
  [key in ECardType | string]: () => void;
};

export const useFetchInfoTypesService = (
  cardName: keyof typeof ECardType,
  query?: ICardQuery
) => {
  const { forceUpdateListIndex } = useKContext();
  const { setListInfoType, setLoadingList } = useListInfoType();
  const { tempShareId, profileId, emergencyAccessId } = useParams<IParams>();
  const cardType = ECardType[cardName];

  const setListInfoTypeMap = useCallback(
    (cardType: ECardType, data: any) => {
      const cardTypeSetMap: ISetListCardTypeMap = {
        [ECardType.Procedures]: () =>
          setListInfoType({
            pastProcedureList: data[0],
            upcomingProcedureList: data[1],
          }),
        [ECardType.Allergies]: () =>
          setListInfoType({
            pastAllergyList: data[0],
            presentAllergyList: data[1],
          }),
        [ECardType.Conditions]: () =>
          setListInfoType({
            pastConditionsList: data[0],
            presentConditionsList: data[1],
          }),
        [ECardType.Medications]: () =>
          setListInfoType({
            pastMedicationList: data[0],
            presentMedicationList: data[1],
          }),
        [ECardType.Appointments]: () =>
          setListInfoType({
            pastAppointmentList: data[0],
            upcomingAppointmentList: data[1],
          }),
        [ECardType.Hospitalizations]: () =>
          setListInfoType({ hospitalizationsList: data[0] }),
        [ECardType.Vaccinations]: () =>
          setListInfoType({
            validVaccinationList: data[0],
            outDateVaccinationList: data[1],
          }),
        [ECardType.Questions]: () =>
          setListInfoType({ unansweredQsList: data[0], otherQsList: data[1] }),
        [ECardType.IdCard]: () =>
          setListInfoType({
            pastCardIdList: data[0],
            presentCardIdList: data[1],
          }),
        [ECardType.Practitioners]: () =>
          setListInfoType({
            pastPractitionerList: data[0],
            presentPractitionerList: data[1],
          }),
        [ECardType.Notes]: () => setListInfoType({ cardNoteList: data[0] }),
        [ECardType.Files]: () => setListInfoType({ cardFileList: data[0] }),
        [ECardType.ToDos]: () =>
          setListInfoType({
            activeToDoList: data[0],
            completedToDoList: data[1],
          }),
      };
      cardTypeSetMap[cardType]?.();
    },
    [setListInfoType]
  );

  const fetchEmergencyList = useCallback(async () => {
    if (!emergencyAccessId) return;
    try {
      setLoadingList(true);
      const queryArray = getQueryListInfoType(cardType);
      const promises = [
        getListCardsEmergencyAccessService<any>(emergencyAccessId, cardType, {
          ...queryArray?.[0],
          ...query,
        }),
      ];
      if (queryArray?.[1]) {
        promises.push(
          getListCardsEmergencyAccessService<any>(
            emergencyAccessId,
            cardType,
            queryArray[1]
          )
        );
      }
      const data = await Promise.all(promises);
      setListInfoTypeMap(cardType, data);
    } catch (error) {
    } finally {
      setLoadingList(false);
    }
  }, [emergencyAccessId, cardType, setListInfoTypeMap, query, setLoadingList]);

  const fetchCardListUser = useCallback(async () => {
    const cardParam = getInfoCard(cardType).path;
    if (!profileId || !cardParam) return;
    try {
      setLoadingList(true);
      const queryArray = getQueryListInfoType(cardType);
      const promises = [
        getListCardsService<any>(profileId, cardParam, {
          ...queryArray?.[0],
          ...query,
        }),
      ];
      if (queryArray?.[1]) {
        promises.push(
          getListCardsService<any>(profileId, cardParam, queryArray[1])
        );
      }
      const data = await Promise.all(promises);
      setListInfoTypeMap(cardType, data);
    } catch (error) {
    } finally {
      setLoadingList(false);
    }
  }, [profileId, cardType, setListInfoTypeMap, query, setLoadingList]);

  const fetchTemporaryShareList = useCallback(async () => {
    if (!tempShareId) return;
    try {
      setLoadingList(true);
      const queryArray = getQueryListInfoType(cardType);
      const promises = [
        getListTemporaryService<any>(tempShareId, cardType, {
          ...queryArray?.[0],
          ...query,
        }),
      ];
      if (queryArray?.[1]) {
        promises.push(
          getListTemporaryService<any>(tempShareId, cardType, queryArray[1])
        );
      }
      const data = await Promise.all(promises);
      setListInfoTypeMap(cardType, data);
    } catch (error) {
    } finally {
      setLoadingList(false);
    }
  }, [tempShareId, cardType, setListInfoTypeMap, query, setLoadingList]);

  useEffect(() => {
    fetchEmergencyList();
    fetchCardListUser();
    fetchTemporaryShareList();
    return () => {
      setListInfoTypeMap(cardType, []);
    };
  }, [profileId, emergencyAccessId, forceUpdateListIndex, query]);
};
