import { apiInstance, authApiInstance } from "@apis";
import { VerifyEmergencyAccessRes, getListParamsDefault } from "@services";
import {
  ECardType,
  IBaseRes,
  ICardQuery,
  IInfoVerifyEmergencyAccess,
  IPagable,
  IPrincipalEmergencyAccessRes,
  IProfileBasic,
  IUserAccessProfile
} from "@types";

const baseURL = "/v1/temporary-share/emergency-access";

export const getPrincipalEmergencyAccessService = async (
  emergencyAccessId: string
): Promise<IPrincipalEmergencyAccessRes> => {
  const res = await authApiInstance.get<IBaseRes<IPrincipalEmergencyAccessRes>>(
    `${baseURL}/${emergencyAccessId}/principal`
  );
  return res?.data?.data;
};

export const verifyEmergencyAccess = async (
  emergencyAccessId: string,
  info: IInfoVerifyEmergencyAccess
) => {
  const res = await authApiInstance.post<VerifyEmergencyAccessRes>(
    `${baseURL}/${emergencyAccessId}/verify`,
    info
  );
  return res?.data?.data;
};

export const getSummaryEmergencyAccessService = async (emergencyAccessId: string) => {
  const { data } = await apiInstance.get(
    `${baseURL}/${emergencyAccessId}/overview`
  );
  return data;
};

export const getProfileEmergencyAccessService = async (emergencyAccessId: string) => {
  const { data } = await apiInstance.get<IBaseRes<IProfileBasic>>(
    `${baseURL}/${emergencyAccessId}/profile-basic`
  );
  return data?.data;
};

export const getListCardsEmergencyAccessService = async <T,>(
  emergencyAccessId: string,
  cardType: ECardType,
  query?: ICardQuery,
) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<T>>>(
    `${baseURL}/${emergencyAccessId}/card/${cardType}`,
    { params: { size: 1000, ...query } }
  );
  return data?.data?.result;
};

export const getCardDetailEmergencyService = async <T>(emergencyAccessId: string, cardType: ECardType, cardId: string) => {
  const { data } = await apiInstance.get<IBaseRes<T>>(`${baseURL}/${emergencyAccessId}/card/${cardType}/${cardId}`);
  return data?.data;
};

export const getUserAccessEmergencyAccess = async (emergencyAccessId: string) => {
  const { data } = await apiInstance.get<IBaseRes<IUserAccessProfile[]>>(`${baseURL}/${emergencyAccessId}/users`);
  return data;
};

export const getCardsRelatedTopicEmergency = async (emergencyAccessId: string, topicId: string) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<any>>>(
    `${baseURL}/${emergencyAccessId}/topic/${topicId}`,
    {
      params: { ...getListParamsDefault }
    }
  );
  return data.data;
};

export const getLinkedInfoToDoEmergency = async (emergencyAccessId: string, cardId: string) => {
  const { data } = await apiInstance.get<IBaseRes<[]>>(
    `${baseURL}/${emergencyAccessId}/card/to-do/${cardId}/linked-info-cards`);
  return data;
}

export * from "./web-app"