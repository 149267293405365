import { Divider, Flex } from "antd";

import { KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { MedicationItem } from "../item";

export const ListMedication = () => {
  const { pastMedicationList = [], presentMedicationList = [], isLoadingList } = useListInfoType();

  useFetchInfoTypesService("Medications");

  return isLoadingList ? (
    <Loading />
  ) : (
    <Flex gap={8} vertical={true}>
      {presentMedicationList.length > 0 && (
        <>
          <KSemiText700>Active Medications</KSemiText700>
          {presentMedicationList.map((item) => (
            <MedicationItem key={item._id} card={item} />
          ))}
        </>
      )}
      {presentMedicationList.length > 0 && pastMedicationList.length > 0 && <Divider />}
      {pastMedicationList.length > 0 && (
        <>
          <KSemiText700>Previous Medications</KSemiText700>
          {pastMedicationList.map((item) => (
            <MedicationItem key={item._id} card={item} />
          ))}
        </>
      )}
    </Flex>
  );
};
