import { Divider } from "antd";

import { KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { ICardConditionRes } from "@types";
import { ConditionItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";

const renderCardItems = (card: ICardConditionRes[]) => {
  return card.map((card: ICardConditionRes) => (
    <ConditionItem key={card._id} card={card} />
  ));
};

export const ListConditions = () => {
  const { presentConditionsList = [], pastConditionsList = [], isLoadingList } =
    useListInfoType();

  useFetchInfoTypesService("Conditions");

  return isLoadingList ? (
    <Loading />
  ) : (
    <WrapperCardList space={16}>
      {presentConditionsList.length > 0 && (
        <CustomSpaceList>
          <KSemiText700>Conditions</KSemiText700>
          {renderCardItems(presentConditionsList)}
        </CustomSpaceList>
      )}
      {presentConditionsList.length > 0 && pastConditionsList.length > 0 && (
        <Divider />
      )}
      {pastConditionsList.length > 0 && (
        <CustomSpaceList>
          <KSemiText700>Past Conditions</KSemiText700>
          {renderCardItems(pastConditionsList)}
        </CustomSpaceList>
      )}
    </WrapperCardList>
  );
};
