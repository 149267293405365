import { KMdText } from "@components";
import { Color, EFontSize } from "@types";
import { Select } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import styled from "styled-components";

const selectStyle = `
  &.ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    box-shadow: none !important;
  }
  &.ant-select-single .ant-select-selector {
    font-size: ${EFontSize.md}px;
    font-weight: 500;
    padding: 0 8px;
  }
`;

export const FilterFile = styled(Select)`
  width: 113px;
  ${selectStyle}
`;

export const SortFile = styled(Select)`
  width: 127px;
  ${selectStyle}
`;

export const IconFilter = styled(CaretDownFilled)`
  color: ${Color.Secondary88};
  font-size: 16px;
`;
