import { Divider, Flex } from "antd";

import { Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { VaccinationItem } from "../item";
import { Section } from "../share-component";

export const ListVaccinations = () => {
  const { validVaccinationList = [], outDateVaccinationList = [], isLoadingList } = useListInfoType();

  useFetchInfoTypesService("Vaccinations");

  return isLoadingList ? (
    <Loading />
  ) : (
    <>
      <Flex gap={16} vertical>
        {validVaccinationList.length > 0 && (
          <Section title="Vaccinations">
            {validVaccinationList.map(i => (
              <VaccinationItem key={i._id} card={i} />
            ))}
          </Section>
        )}
        {validVaccinationList.length > 0 && outDateVaccinationList.length > 0 && <Divider />}
        {outDateVaccinationList.length > 0 && (
          <Section title="Out of Date Vaccinations">
            {outDateVaccinationList.map(i => (
              <VaccinationItem key={i._id} card={i} />
            ))}
          </Section>
        )}
      </Flex>
    </>
  );
};
