import { KFormUser } from "@components";
import { MedicationMeasureOptions, MedicationStatusOptions, MedicationTypeOptions } from "@constants/options";
import { Flex } from "antd";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const MedicationDetail = () => {
  return (
    <>
      <Flex vertical gap={8}>
        <KFormUser.TextArea
          name="instructions"
          label="Instructions"
          placeholder="Enter any guidance or important details"
        />
      </Flex>
      <Flex justify="space-between" gap={16}>
        <KFormUser.DatePicker
          label="Start Date"
          name="startTime"
        />
        <KFormUser.EndDatePicker
          label="End Date"
          name="endTime"
        />
      </Flex>
      <KFormUser.Select
        name="status"
        label="Status"
        options={MedicationStatusOptions}
      />
      <KFormUser.Switch
        name="takeAsNeeded"
        label="Take as needed?"
      />
      <KFormUser.Switch
        name="prescription.prescription"
        label="Prescription"
      />
      <KFormUser.Switch
        name="supplement"
        label="Supplement?"
      />
      <KFormUser.Input
        name="reason"
        label="Reason"
        placeholder="Reason"
      />
      <Flex justify="space-between" gap={16}>
        <KFormUser.InputUnit
          name="dosage"
          label="Dosage"
          options={MedicationMeasureOptions}
          selectName="measurement"
        />
        <KFormUser.Select
          name="type"
          label="Type"
          options={MedicationTypeOptions}
        />
      </Flex>
      <KFormUser.TextArea
        name="description"
        label="Notes"
      />
      <SetReminderSection />
      <AttachmentsDetail />
    </>
  );
};
