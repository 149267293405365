import { Flex } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "@components";
import { useKContext } from "@context";
import {
  getCardsRelatedTopicEmergency,
  getCardsRelatedTopicTempShare,
  getCardsRelatedToTopicService,
} from "@services";
import { ECardType, EOrder, EOrderBy, ICardQuery, IParams } from "@types";
import {
  AllergyItem,
  AppointmentItem,
  ConditionItem,
  FilesItem,
  HospitalizationItem,
  IdCardItem,
  MedicationItem,
  NoteItem,
  PractitionerItem,
  ProcedureItem,
  QuestionItem,
  ToDoItem,
  VaccinationItem,
} from "../..";
import { MeatBallTopic } from "./MeatBallTopic";
import { useProfile } from "@store";

const componentsMap = (card: any) => {
  const props = {
    card,
    key: card._id,
    showIcon: true,
  };

  return {
    [ECardType.Allergies]: <AllergyItem {...props} />,
    [ECardType.Appointments]: <AppointmentItem {...props} />,
    [ECardType.Conditions]: <ConditionItem {...props} />,
    [ECardType.Files]: <FilesItem {...props} />,
    [ECardType.Hospitalizations]: <HospitalizationItem {...props} />,
    [ECardType.IdCard]: <IdCardItem {...props} />,
    [ECardType.Medications]: <MedicationItem {...props} />,
    [ECardType.Notes]: <NoteItem {...props} />,
    [ECardType.Practitioners]: <PractitionerItem {...props} />,
    [ECardType.Procedures]: <ProcedureItem {...props} />,
    [ECardType.Questions]: <QuestionItem {...props} />,
    [ECardType.Vaccinations]: <VaccinationItem {...props} />,
    [ECardType.ToDos]: <ToDoItem {...props} showCheckbox={false} />,
  };
};

export const ListCardsRelatedTopic = () => {
  const { profileId, tempShareId, emergencyAccessId, cardType, cardId } =
    useParams<IParams>();
  const { setCardTypeSelected } =
    useKContext();
  const { profile } = useProfile();
  const [list, setList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!cardType) return;
    (async () => {
      try {
        setIsLoading(true);
        let res;
        if (tempShareId) {
          res = await getCardsRelatedTopicTempShare(tempShareId, cardType);
        }
        if (profileId) {
          res = await getCardsRelatedToTopicService(profileId, cardType);
        }
        if (emergencyAccessId) {
          res = await getCardsRelatedTopicEmergency(
            emergencyAccessId,
            cardType
          );
        }
        if (!res) return;
        setList(res.result);
        const item = res.result.find((i) => i._id === cardId);
        if (item) {
          setCardTypeSelected(item.cardType);
        }
      } catch {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [tempShareId, profileId, emergencyAccessId, cardType]);

  return isLoading ? (
    <Loading />
  ) : (
    <Flex gap={16} vertical>
      {profileId && (profile?.emergencyAccessTopicId !== cardType) && (
        <MeatBallTopic />
      )}
      <Flex gap={8} vertical>
        {list.map((i) => {
          const Item =
            componentsMap(i)[i.cardType as keyof typeof componentsMap];
          return Item;
        })}
      </Flex>
    </Flex>
  );
};
