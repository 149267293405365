import {
  PaperClipOutlined
} from '@ant-design/icons';
import {
  KMdText,
  KMdText700,
  KSmTextSecondary65,
  KTag,
  KXlText700,
} from "@components";
import { useKContext } from '@context';
import {
  ECardType,
  EDateTimeFormat,
  IAttachment,
  ICombinedCard,
  IItemCardProps,
  IParams
} from "@types";
import { getLabelOfField, ignoreFields } from '@utils/index';
import { Divider, Flex, FlexProps, Space } from "antd";
import moment from "moment";
import { FC, ReactNode, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom';
import {
  AllergyItem,
  AppointmentItem,
  ConditionItem,
  FilesItem,
  HospitalizationItem,
  IdCardItem,
  MedicationItem,
  NoteItem,
  PractitionerItem,
  ProcedureItem,
  QuestionItem,
  ToDoItem,
  VaccinationItem,
} from "./item";

export const CustomSpaceList = ({
  children,
  space = 8,
}: {
  children: React.ReactNode;
  space?: number;
}) => (
  <Space direction="vertical" size={space} style={{ width: "100%" }}>
    {children}
  </Space>
);

interface IWrapperCardList extends FlexProps {
  space?: number;
}

export const WrapperCardList = ({
  children,
  space = 8,
  ...props
}: IWrapperCardList) => (
  <Flex
    vertical
    gap={space}
    style={{
      overflowY: "auto",
      maxHeight: "100vh",
    }}
    {...props}
  >
    {children}
  </Flex>
);

interface ISectionProps {
  title: string;
  children: ReactNode;
}

export const Section = ({ children, title }: ISectionProps) => (
  <Flex gap={8} vertical>
    <KXlText700>{title}</KXlText700>
    <Flex gap={8} vertical>
      {children}
    </Flex>
  </Flex>
);

export const MetadataDetail: FC<any> = ({
  updatedBy,
  updatedTime,
  createdBy,
  createdTime,
}) => {
  const fullName = useMemo(() => {
    const firstName = updatedBy?.firstName || createdBy?.firstName;
    const lastName = updatedBy?.lastName || createdBy?.lastName;
    return `${firstName} ${lastName?.charAt(0)}.`;
  }, [updatedBy, createdBy]);

  const time = useMemo(() => {
    const timestamp = updatedTime || createdTime;
    return moment(timestamp).format(
      EDateTimeFormat.FULL_MONTH_YEAR_WITH_TIME_AM_PM
    );
  }, [updatedTime, createdTime]);

  return (
    <>
      <Divider />
      <KSmTextSecondary65>
        Modified by {fullName} {time}
      </KSmTextSecondary65>
    </>
  );
};

interface ICardItemContainer extends FlexProps {
  card?: any;
  showMetadata?: boolean;
};

export const CardItemContainer: FC<ICardItemContainer> = ({
  card,
  children,
  showMetadata = false,
  ...props
}) => {
  const { cardId } = useParams<IParams>();
  const { setCardTypeSelected } = useKContext();
  const { matchFields, attachments, cardType, _id } = card || {};
  useEffect(() => {
    if (_id !== cardId) return;
    setCardTypeSelected(cardType);
  }, [cardType, _id, cardId]);

  return (
    <Flex
      style={{ maxWidth: "100%" }}
      gap={8}
      vertical
      justify="space-between"
      flex={1}
      {...props}
    >
      {children}
      {matchFields?.length > 0 &&
        matchFields.map(
          (field: keyof ICombinedCard, idx: number) =>
            !ignoreFields.includes(field) && (
              <Flex gap={4} key={idx}>
                <KMdText700>{getLabelOfField(cardType, field)}:</KMdText700>
                <KMdText $ellipsis>{card[field]}</KMdText>
              </Flex>
            )
        )}
      {(showMetadata && (!!attachments?.length)) && (
        <Flex wrap="wrap" gap={4}>
          {attachments.map((atm: IAttachment) => (
            <KTag key={atm._id} icon={<PaperClipOutlined />}>
              {atm.originalName}
            </KTag>
          ))}
        </Flex>)
      }
      {showMetadata && <MetadataDetail {...card}/>}
    </Flex>
  );
};

export const cardTypeToCardItem: {
  [key: string]: { CardItem: React.ComponentType<IItemCardProps<any>> };
} = {
  [ECardType.Allergies]: { CardItem: AllergyItem },
  [ECardType.Appointments]: { CardItem: AppointmentItem },
  [ECardType.Conditions]: { CardItem: ConditionItem },
  [ECardType.Files]: { CardItem: FilesItem },
  [ECardType.Hospitalizations]: { CardItem: HospitalizationItem },
  [ECardType.IdCard]: { CardItem: IdCardItem },
  [ECardType.Medications]: { CardItem: MedicationItem },
  [ECardType.Notes]: { CardItem: NoteItem },
  [ECardType.Practitioners]: { CardItem: PractitionerItem },
  [ECardType.Procedures]: { CardItem: ProcedureItem },
  [ECardType.Questions]: { CardItem: QuestionItem },
  [ECardType.Vaccinations]: { CardItem: VaccinationItem },
  [ECardType.ToDos]: { CardItem: ToDoItem },
};
