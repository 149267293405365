import { Divider, Flex } from "antd";

import { KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { ProcedureItem } from "../item";

export const ListProcedure = () => {
  const { pastProcedureList = [], upcomingProcedureList = [], isLoadingList } = useListInfoType();

  useFetchInfoTypesService("Procedures");

  return isLoadingList ? (
    <Loading />
  ) : (
    <Flex gap={8} vertical={true}>
      {upcomingProcedureList.length > 0 && (
        <>
          <KSemiText700>Upcoming Procedures</KSemiText700>
          {upcomingProcedureList.map((item) => (
            <ProcedureItem key={item._id} card={item} />
          ))}
        </>
      )}
      {upcomingProcedureList.length > 0 && pastProcedureList.length > 0 && <Divider />}
      {pastProcedureList.length > 0 && (
        <>
          <KSemiText700>Past Procedures</KSemiText700>
          {pastProcedureList.map((item) => (
            <ProcedureItem key={item._id} card={item} />
          ))}
        </>
      )}
    </Flex>
  );
};
