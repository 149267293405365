import { Divider, Flex } from "antd";

import { Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { QuestionItem } from "../item";
import { Section } from "../share-component";

export const ListQuestions = () => {
  const { unansweredQsList = [], otherQsList = [], isLoadingList } = useListInfoType();

  useFetchInfoTypesService("Questions");

  return isLoadingList ? (
    <Loading />
  ) : (
    <>
      <Flex gap={16} vertical>
        {unansweredQsList.length > 0 && (
          <Section title="Active Questions">
            {unansweredQsList.map(i => (
              <QuestionItem key={i._id} card={i} />
            ))}
          </Section>)}
        {unansweredQsList.length > 0 && otherQsList.length > 0 && <Divider />}
        {otherQsList.length > 0 && (
          <Section title="Answered Questions">
            {otherQsList.map(i => (
              <QuestionItem key={i._id} card={i} />
            ))}
          </Section>)}
      </Flex>
    </>
  );
};
