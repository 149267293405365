import { Divider } from "antd";

import { DividerLeftText, KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { ICardAppointmentRes } from "@types";
import { isPastDate, isToday, isTomorrow, isUpcoming } from "@utils/date";
import { AppointmentItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";

export const ListAppointments = () => {
  const { pastAppointmentList = [], upcomingAppointmentList = [], isLoadingList } =
    useListInfoType();

  useFetchInfoTypesService("Appointments");

  const renderCardItems = (card: ICardAppointmentRes[]) => {
    return card.map((card: ICardAppointmentRes) => (
      <AppointmentItem key={card._id} card={card} />
    ));
  };

  const renderAppointmentsByDate = (
    dateCondition: (item: ICardAppointmentRes) => boolean,
    label: string,
    appointments: ICardAppointmentRes[]
  ) => {
    const filteredAppointments = appointments.filter(dateCondition);

    return filteredAppointments.length > 0 ? (
      <CustomSpaceList space={16}>
        {label && <DividerLeftText>{label}</DividerLeftText>}
        {renderCardItems(filteredAppointments)}
      </CustomSpaceList>
    ) : null;
  };

  return isLoadingList ? (
    <Loading />
  ) : (
    <WrapperCardList space={16}>
      {upcomingAppointmentList.length > 0 && (
        <CustomSpaceList>
          <KSemiText700>Upcoming Appointments</KSemiText700>
          <CustomSpaceList space={24}>
            {renderAppointmentsByDate(
              (item) => isPastDate(item.startTime),
              "",
              upcomingAppointmentList
            )}
            {renderAppointmentsByDate(
              (item) => isToday(item.startTime) || !item.startTime,
              "TODAY",
              upcomingAppointmentList
            )}
            {renderAppointmentsByDate(
              (item) => isTomorrow(item.startTime),
              "TOMORROW",
              upcomingAppointmentList
            )}
            {renderAppointmentsByDate(
              (item) => isUpcoming(item.startTime),
              "UPCOMING",
              upcomingAppointmentList
            )}
          </CustomSpaceList>
        </CustomSpaceList>
      )}
      {pastAppointmentList.length > 0 && upcomingAppointmentList.length > 0 && (
        <Divider />
      )}
      {pastAppointmentList.length > 0 && (
        <CustomSpaceList>
          <KSemiText700>Past Appointments</KSemiText700>
          {renderCardItems(pastAppointmentList)}
        </CustomSpaceList>
      )}
    </WrapperCardList>
  );
};
