import { Flex } from "antd";

import { Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { NoteItem } from "../item";

export const ListNotes = () => {
  const {
    cardNoteList = [],
    isLoadingList,
  } = useListInfoType();

  useFetchInfoTypesService("Notes");

  return isLoadingList ? (
    <Loading />
  ) : (
    <>
      <Flex gap={8} vertical>
        {cardNoteList.map((i) => (
          <NoteItem key={i._id} card={i} showMetadata />
        ))}
      </Flex>
    </>
  );
};
