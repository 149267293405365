import { Divider } from "antd";

import { KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { ICardTodoRes } from "@types";
import { ToDoItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";

const renderCardItems = (filteredCards: ICardTodoRes[]) => {
  return filteredCards.map((card: ICardTodoRes) => (
    <ToDoItem key={card._id} card={card} />
  ));
};

const renderToDos = (title: string, cardToDos: ICardTodoRes[]) => {
  return (
    cardToDos.length > 0 && (
      <CustomSpaceList>
        <KSemiText700>
          {title}
        </KSemiText700>
        {renderCardItems(cardToDos)}
      </CustomSpaceList>
    )
  );
};

export const ListToDos = () => {
  const { activeToDoList = [], completedToDoList = [], isLoadingList } = useListInfoType();

  useFetchInfoTypesService("ToDos");

  return isLoadingList ? (
    <Loading />
  ) : (
    <WrapperCardList space={16}>
      {renderToDos("Active To-Dos", activeToDoList)}
      {(!!activeToDoList?.length && !!completedToDoList?.length) && <Divider />}
      {renderToDos("Completed To-Dos", completedToDoList)}
    </WrapperCardList>
  );
};
